<template>
    <BT-Blade-Items
        addBladeName="role"
        bladeName="roles"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        flexColumn
        :headers="[
            { text: 'Name', value: 'roleName', title: 1, searchable: true },
            { text: 'Description', value: 'description', subtitle: 1 }]"
        navigation="roles"
        title="Roles"
        useServerPagination />
</template>

<script>
export default {
    name: 'Roles-Blade',
    props: {
        bladesData: null
    }
}
</script>